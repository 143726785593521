import { Card, Col, Row, Typography, message } from "antd";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import jsSha512 from "js-sha512";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { goToAdminPortal } from "../helpers/redirect";
import { API } from "../service/api.request";

export function Otp(props) {
  const history = useHistory();
  const email = localStorage?.getItem("email_forget_pass");
  const parsedData = JSON.parse(email);
  const initialValues = {
    code: undefined,
  };
  const OtpSchema = Yup.object({
    code: Yup.string()
      .required("Otp required")
      .matches(/^[1-9]\d{5}$/, {
        message: "Please enter valid otp.",
        excludeEmptyString: false,
      }),
  });

  function handleSubmit(values, { setErrors, setSubmitting }) {
    const valueCopy = { ...values };
    valueCopy.email = parsedData.email;
    const CREDENTIALS = {
      url: `/users/password-reset-token`,
      method: "post",
      data: valueCopy,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        showSuccessMessage(response.data.user);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function showSuccessMessage(response) {
    message.success("Now you can reset your Password.");
    localStorage.setItem("reset", JSON.stringify(response));
    setTimeout(() => {
      history.push("/reset-password");
    }, 2000);
  }

  return (
    <Row justify="center" align="middle" style={{ paddingTop: "75px" }}>
      <Col span={6}>
        <div className="center" style={{ marginBottom: "50px" }}>
          <h1>DFS LOGO</h1>
        </div>

        <Card className="login-box">
          <p className="center" style={{ marginBottom: "30px" }}>
            Please Enter Otp
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={OtpSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      name="code"
                      hasFeedback={true}
                      showValidateSuccess={true}
                    >
                      <Input
                        name="code"
                        placeholder=" Enter Email Otp"
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={24} className="align-right">
                    <Link className="forgot-link" to="/forgot-password">
                      BACK TO FORGET PASSWORD
                    </Link>
                  </Col>
                </Row>

                <SubmitButton
                  data-testid="form-save-btn"
                  type="primary"
                  disabled={isSubmitting}
                  className="login-btn"
                >
                  Reset Password
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
  );
}

export default Otp;
