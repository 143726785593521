import { Card, Col, Row, Typography, message as toaster } from "antd";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import jsSha512 from "js-sha512";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { goToAdminPortal } from "../helpers/redirect";
import { API } from "../service/api.request";
import logo_main from "../assets/logo_main.svg";

const { Title } = Typography;

const initialValues = {
  email: undefined,
  password: undefined,
};
const LoginSchema = Yup.object({
  email: Yup.string().email().required("Email required"),
  password: Yup.string().required("Password required"),
});

export function Login() {

  function handleSubmit(values, { setErrors, setSubmitting, resetForm }) {

    let ValueCopy = {
      email: values.email,
      password: jsSha512(values.password),
      os: "web",
    };

    const CREDENTIALS = {
      url: `/users/login`,
      method: "post",
      data: ValueCopy,
      setErrors,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        handleSucess(response);
        resetForm({});
      })
      .finally(() => {
        setSubmitting(false);
      });
  }


  const handleSucess = (response) => {
    try {
      const user = response.data.user;
      user.markets = user?.markets
        ?.filter((item) => item?.active === 1)
        ?.map((item) => item?.market?.id);
      let data = JSON.stringify(user);
      if (user?.userType?.id === 3 || user?.userType?.id === 2) {
        toaster.error("Credentials provided is invalid.");
      } else {
        goToAdminPortal(data);
      }
    } catch (e) {
      handleError(e);
    }
  };


  function handleError(e) {
    toaster.error("Unexpected error happened");
    console.log("error", e);
  }

  return (
    <Row justify="center" align="middle" style={{ paddingTop: "75px" }}>
      <Col span={6}>
        <div className="center" style={{ marginBottom: "50px" }}>
          <img src={logo_main} alt="dfs_logo" width="90%" />
        </div>
        <Card className="login-box">
          <Title className="center" level={2}>
            Login
          </Title>
          <p className="center" style={{ marginBottom: "30px" }}>
            Enter your details below.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      name="email"
                      hasFeedback={true}
                      showValidateSuccess={true}
                    >
                      <Input name="email" placeholder="Enter Email ID" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      hasFeedback={true}
                      showValidateSuccess={true}
                    >
                      <Input.Password
                        name="password"
                        placeholder="Type Your Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24} className="align-right">
                    <Link className="forgot-link" to="/forgot-password">
                      FORGOT PASSWORD
                    </Link>
                  </Col>
                </Row>

                <SubmitButton
                  data-testid="form-save-btn"
                  type="primary"
                  disabled={isSubmitting}
                  className="login-btn"
                >
                  Login
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
