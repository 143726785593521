import qs from 'qs';
import { message as toaster } from 'antd';
import { isArray } from 'lodash';

import axios from './api.config';

class ApiRequestClass {
	// constructor() {
	// 	console.log('instance', ApiRequestClass.instance, this);
	// }
	/**
	 *
	 * @param {string} url
	 * @param {object} data
	 * @param {boolean} showLoader
	 * @param {string} method - must be a lowercase, it's axios get, post, delete, patch methods
	 * @param {object} queryParams
	 * @param {object} config - we can set runtime axios headers
	 */
	async common({
		url,
		data = {},
		showLoading = true,
		method = 'get',
		queryParams = {},
		setErrors,
		config = {},
	}) {
		if (showLoading) {
			// this.handleShowLoader();
		}

		try {
			let URL = this.addQueryParamsWithUrl(url, queryParams);
			const response = await axios[method](URL, data, config);
			return Promise.resolve(response?.data);
		} catch (error) {
			this.handleErrors(error, setErrors);
			return Promise.reject(error);
		} finally {
			// this.handleHideLoader();
		}
	}

	addQueryParamsWithUrl(url, queryParams) {
		Object.keys(queryParams).forEach(
			(key) =>
				(queryParams[key] === null || queryParams[key] === '') &&
				delete queryParams[key]
		);
		return url + qs.stringify(queryParams, { addQueryPrefix: true });
	}

	handleErrors(error, setErrors) {
		console.log('errors error', error);
		if (error) {
			try {
				const data = error.response.data;
				const status = error.response.status;
				console.log('errors data', data);
				console.log('errors status', status);
				let checkNetworkError = JSON.stringify(error);
				const NetworkError = 'Network Error';

				if (checkNetworkError.includes(NetworkError)) {
					toaster.error(NetworkError);
					return false;
				} else if (status === 500) {
					toaster.error(error.message);
				} else {
					// const txt = data.errors.message;
					// toaster.error(txt);
					// return error;
					this.handleCommonErrors(data);
				}
			} catch (error) {
				toaster.error('Something went wrong please try again');
				console.log('Unhandled error', error);
			}
		}
	}

	handleCommonErrors(data) {
		const error = data.errors.message;
		const errors = data.errors.messages;
		if (error) {
			if (isArray(error)) {
				toaster.error(error[0]);
			} else if (!isArray(error)) {
				toaster.error(error);
			}
		} else if (errors) {
			if (isArray(errors)) {
				toaster.error(errors[0]);
			} else if (!isArray(errors)) {
				toaster.error(errors);
			}
		}
	}
}

export const API = new ApiRequestClass();
