import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { ForgotPassword, Login,Otp,ResetPassword } from './pages';
export default function Router() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Login} />
				<Route
					exact
					path="/forgot-password"
					component={ForgotPassword}
				/>
				<Route
					exact
					path="/otp"
					component={Otp}
				/>
				<Route
					exact
					path="/reset-password"
					component={ResetPassword}
				/>
				<Route path="*">
					<Redirect
						to={{
							pathname: '/',
						}}
					/>
				</Route>
			</Switch>
		</BrowserRouter>
	);
}
