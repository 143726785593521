import React from "react";
import { Row, Col, Card, Typography, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { API } from "../service/api.request";
import { values } from "lodash";

const { Title } = Typography;

const initialValues = {
  email: undefined,
};
const ForgotScheme = Yup.object({
  email: Yup.string().email().required("Email required"),
});

export function ForgotPassword() {
  const history = useHistory();

  function handleSubmit(values, { setErrors, setSubmitting }) {
    const CREDENTIALS = {
      url: `/users/forgot-password`,
      method: "post",
      data: values,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        showSuccessMessage(values);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function showSuccessMessage(values) {
    message.success(
      "Temporary password has been sent to your registered email."
    );
    localStorage.setItem("email_forget_pass", JSON.stringify(values));
    setTimeout(() => {
      history.push({ pathname: "/otp" });
    }, 2000);
  }

  return (
    <Row justify="center" align="middle" style={{ paddingTop: "75px" }}>
      <Col span={6}>
        <div className="center" style={{ marginBottom: "50px" }}>
          <h1>DFS LOGO</h1>
        </div>

        <Card className="login-box">
          <Title className="center" level={2}>
            Forgot Password
          </Title>
          <p className="center" style={{ marginBottom: "30px" }}>
            Enter your email below.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={ForgotScheme}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      name="email"
                      hasFeedback={true}
                      showValidateSuccess={true}
                    >
                      <Input name="email" placeholder=" Enter Email ID" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={24} className="align-right">
                    <Link className="forgot-link" to="/">
                      BACK TO LOGIN
                    </Link>
                  </Col>
                </Row>

                <SubmitButton
                  data-testid="form-save-btn"
                  type="primary"
                  disabled={isSubmitting}
                  className="login-btn"
                >
                  Confirm
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
