import { Card, Col, Row, Typography, message } from "antd";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import jsSha512 from "js-sha512";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { API } from "../service/api.request";

export function ResetPassword() {
  const history = useHistory();
  const email = localStorage.getItem("email_forget_pass");
  const parsedData = JSON.parse(email);
  const resetPassword = localStorage.getItem("reset");
  const parsedToken = JSON.parse(resetPassword);

  
  const initialValues = {
    password: undefined,
  };
  const ResetTokenSchema = Yup.object({
    password: Yup.string().required("Password required"),
  });

  function handleSubmit(values, { setErrors, setSubmitting }) {
    const valueCopy = { ...values };
    valueCopy.email = parsedData.email;
    valueCopy.resetToken = parsedToken.resetToken;
    valueCopy.password = jsSha512(valueCopy?.password);
    const CREDENTIALS = {
      url: `/users/reset-password`,
      method: "post",
      data: valueCopy,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        showSuccessMessage();
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function showSuccessMessage(response) {
    message.success("Password changed Successfully.");
    localStorage.setItem("email_forget_pass", "");
    localStorage.setItem("reset", "");
    setTimeout(() => {
      history.push("/");
    }, 2000);
  }

  return (
    <Row justify="center" align="middle" style={{ paddingTop: "75px" }}>
      <Col span={6}>
        <div className="center" style={{ marginBottom: "50px" }}>
          <h1>DFS LOGO</h1>
        </div>

        <Card className="login-box">
          <p className="center" style={{ marginBottom: "30px" }}>
            Enter New Password
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={ResetTokenSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      hasFeedback={true}
                      showValidateSuccess={true}
                    >
                      <Input.Password
                        name="password"
                        placeholder=" Enter Password"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={24} className="align-right">
                    <Link className="forgot-link" to="/">
                      BACK TO LOGIN
                    </Link>
                  </Col>
                </Row>

                <SubmitButton
                  data-testid="form-save-btn"
                  type="primary"
                  disabled={isSubmitting}
                  className="login-btn"
                >
                  Reset Password
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
  );
}

export default ResetPassword;
